export const humanizeEnum = (enumValue: string | null | undefined): string => {
  if (!enumValue) {
    return "";
  }

  return enumValue
    .replace(/([a-z])([A-Z])/g, "$1 $2") // Insert space between lowercase and uppercase letters
    .replace(/([A-Z])([A-Z][a-z])/g, "$1 $2") // Handle consecutive capitals
    .replace(/^./, (str) => str.toUpperCase()) // Capitalize the first letter
    .trim();
};
